.partner_section {
  background-color: var(--off-white);
  padding: 3rem 0 0 0;
}

.partner_title {
  display: flex;
  justify-content: center;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* justify-content: space-between; */
  padding: 2rem 0 4rem 0;
}

/* Responsive layout - makes a one column-layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-item-right,
  .flex-item-left {
    flex: 100%;
  }

  .flex-container {
    justify-content: space-evenly;
  }
}
