/* header */
.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  background-color: var(--primary-color);
  padding: 0.5rem 3rem;
  position: sticky;
  width: 100%;
}
.header .header-img {
  max-height: 4rem;
  width: auto;
}

.header a {
  text-decoration: none;
  color: inherit;
}

.list a {
  font-size: 16px;
  /* margin: 0.5rem 0 auto; */
  text-decoration: none;
  color: inherit;
  border: 0;
  display: inline-block;
  cursor: pointer;
}

.list a:hover {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.list {
  list-style: none;
  display: flex;
  gap: 25px;
}

#toggler,
.header label {
  display: none;
}

@media screen and (max-width: 800px) {
  .menu {
    width: 100%;
    max-height: 0;
    overflow: hidden;
  }
  .list {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .header label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  #toggler:checked ~ .menu {
    max-height: 100%;
  }
}
