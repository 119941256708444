@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

:root {
  --primary-color: #1e3a8a;
  --secondary-color: #93c5fd;
  --off-white: #dbeafe;
  --white: #ffffff;
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.application {
  flex: 1;
}
