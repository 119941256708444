.ourService {
  padding: 3rem 0;
}

.ourService_container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem 0;
}

.ourService_header .title {
  display: flex;
  justify-content: center;
}

.ourService .card {
  width: 19rem;
}

.ourService .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ourService .card a {
  background-color: var(--primary-color);
}

.ourService_container .card-header {
  background-color: var(--primary-color);
  color: var(--white);
}
