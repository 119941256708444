.whywe {
  padding: 3rem;
  background-color: var(--off-white);
}

.whywe_header .title {
  display: flex;
  justify-content: center;
}

.whywe .card .row .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whywe .card .row .col img {
  max-width: 30rem;
  padding: 2rem 0;
}

.whywe h3,
h4 {
  margin-top: 1rem 0;
  padding: 1rem;
}

.whywe p {
  padding: 0 1rem;
}

@media screen and (max-width: 480px) {
  .whywe .card .row .col img {
    max-width: 15rem;
  }
}
