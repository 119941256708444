/* hero */

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-img {
  margin: 3rem;
  max-height: 400px;
}

.hero-heading {
  color: var(--primary-color);
  font-size: 6rem;
  margin: -1rem;
}

.hero-subheading {
  font-size: x-large;
  font-weight: bold;
}
