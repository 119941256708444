.footer {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 3rem 0 2rem 0;
}

.footer li {
  list-style: none;
  white-space: nowrap;
}

.footer li a {
  text-decoration: none;
  color: inherit;
}

.footer-social li {
  display: inline;
  font-size: x-large;
  margin: 0 5px;
}

.footer-social {
  padding-left: 0;
}
