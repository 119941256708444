.about-banner {
  background-image: url(/public/assets/images/dream.jpg);
  height: 40rem;
  background-size: cover;
  background-position: right center;
}

@media (max-width: 767px) {
  .about-banner {
    height: 20rem;
    background-position: center center;
  }
}
