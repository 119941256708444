.box {
  padding: 2rem;
  color: black;
  border: none;
}

.box h2 {
  display: flex;
  justify-content: center;
}

.box .title {
  margin-bottom: 2rem;
}

.box .paragraph {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

.box p {
  margin: 10px;
}
