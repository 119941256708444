.content li {
  list-style: none;
}

.content {
  display: flex;
  justify-content: space-evenly;
}

@media screen and (max-width: 480px) {
  .content {
    display: flex;
    flex-direction: column;
  }
}
